









































































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import AuthService from "@/services/AuthService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import UserDTO from "@/dto/UserDTO";
import ChangePasswordRequestDTO from "@/dto/auth/ChangePasswordRequestDTO";
import {WorkspaceType} from "@/dto/auth/Workspace";
import UserDataDTO from "@/dto/UserDataDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PersonService from "@/services/PersonService";
import CompanyService from "@/services/CompanyService";
import MoveCustomerOnAnotherAccountRequest from "@/dto/auth/MoveCustomerOnAnotherAccountRequest";
import AdminService from "@/services/AdminService";
import Workspaces from "@/state/Workspaces";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	components: {PortalCheckbox}
})
export default class AccountSettings extends Vue {

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    @Auth.State("user")
    private currentUser!: UserDTO;

    private moveRequest: MoveCustomerOnAnotherAccountRequest = new MoveCustomerOnAnotherAccountRequest();

    private message = "";

    private successful = false;

    private invitationSent = false;

    private notificationEnable = false;

    private portalNotificationsEnable = false;

	private isClient = false;

    private changePasswordRequest = new ChangePasswordRequestDTO();

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void


    private workspaceUser: UserDataDTO | null = null;

	public getIsClientFlag(): void {
		switch (Workspaces.getCurrentType) {
			case WorkspaceType.COMPANY:
				this.startLoading()
				CompanyService.getIsClientStatus(this.$wss.getCurrent.id).then(
					res => {
						this.isClient = res.data
						this.stopLoading()
					},
					err => {
						this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
						this.successful = false;
						this.stopLoading();
					}
				);
				break;
			case WorkspaceType.PERSON:
				PersonService.getIsClientStatus(this.$wss.getCurrent.id).then(
					res => {
						this.isClient = res.data
						this.stopLoading()
					},
					err => {
						this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
						this.successful = false;
						this.stopLoading();
					}
				);
		}
	}

	private setIsClientFlag(): void {
		console.log(this.isClient)
		switch (Workspaces.getCurrentType) {
			case WorkspaceType.COMPANY:
				this.startLoading();
				CompanyService.setIsClientStatus(this.$wss.getCurrent.id, this.isClient).then(
					() => {
						this.getIsClientFlag();
						this.stopLoading()
					},
					err => {
						this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
						this.successful = false;
						this.stopLoading();
					}
				)
				break
			case WorkspaceType.PERSON:
				this.startLoading();
				PersonService.setIsClientStatus(this.$wss.getCurrent.id, this.isClient).then(
					() => {
						this.getIsClientFlag();
						this.stopLoading()
					},
					err => {
						this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
						this.successful = false;
						this.stopLoading();
					}
				)
		}
	}



mounted() {
		this.getIsClientFlag();
	    switch (Workspaces.getCurrentType) {
		    case WorkspaceType.SUBLIME_EMPLOYEE:
		    case WorkspaceType.PERSON:
			    this.loadUserIfPossible();
			    break;
		    case WorkspaceType.COMPANY:
			    this.loadUserIfPossible()
			    break;
	    }
    }

    private editEmailMode = false;


    private loadUserIfPossible() {
        if (this.hasAdministrativePrivileges) {
            this.getAndSetPortalNotificationsEnable();

        }
        if (Workspaces.getCurrent.userId) {
            this.message = "";
            this.startLoading();
            return AuthService.getUser(Workspaces.getCurrent.userId).then(
                response => {
                    this.workspaceUser = response.data;
                    this.notificationEnable = response.data.isNotificationEnable as boolean;
                    this.successful = true;
                    this.stopLoading();
                },
                error => {
                    this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                    this.successful = false;
                    this.stopLoading();
                }
            );
        } else {
            this.workspaceUser = null;
            return Promise.resolve();
        }
    }

    private getAndSetPortalNotificationsEnable() {
        AdminService.getPortalNotificationsEnable().then(
            (res) => this.portalNotificationsEnable = res.data,
            (err) => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                this.successful = false;
                this.stopLoading();
            }
        )
    }

    private toggleNotificationEnable() {
        this.message = "";
        this.startLoading();
        AuthService.toggleNotificationEnable(Workspaces.getCurrent.userId, !this.notificationEnable as boolean).then(
            () => {
                this.stopLoading();
                this.successful = true;
                this.loadUserIfPossible();
                this.message = "The notification settings have been saved!";
            },
            error => {
                this.stopLoading();
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
            }
        );
    }

    private togglePortalNotificationEnable() {
        this.message = "";
        this.startLoading();
        AdminService.togglePortalNotificationEnable().then(
            () => {
                this.stopLoading();
                this.successful = true;
                this.loadUserIfPossible();
                this.message = "The portal notification settings have been saved!";
            },
            error => {
                this.stopLoading();
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
            }
        );
    }

    private sendInvitation() {
        this.message = "";
        this.startLoading();
        AuthService.sendInvitation(Workspaces.getCurrent.userId).then(
            () => {
                this.stopLoading();
                this.invitationSent = true;
                this.successful = true;
                this.message = "Invitation link sent";
            },
            error => {
                this.stopLoading();
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
            }
        );
    }

    get showInvitation(): boolean {
        return this.hasAdministrativePrivileges && this.currentUser.id != Workspaces.getCurrent.userId;
    }

    get showChangePassword(): boolean {
        return this.currentUser.id === Workspaces.getCurrent.userId;
    }

    private changePassword() {
        this.message = "";
        this.startLoading();
        AuthService.changePassword(this.changePasswordRequest).then(
            () => {
                this.message = "Password successfully changed";
                this.successful = true;
                this.stopLoading();
            },
            error => {
                this.errorHandling(error);
            }
        );
    }
    private changeAccount() {
        this.moveRequest.customerId = Workspaces.getCurrent.id;
        switch (Workspaces.getCurrent.type) {
            case WorkspaceType.PERSON:
                this.changeAccountForPerson();
                break;
            case WorkspaceType.COMPANY:
                this.changeAccountForCompany();
                break;
            default:
                console.log("Error! Unknown customer type!");
        }
    }

    private changeAccountForPerson() {
        this.$validator.validate('email').then(
            isValid => {
                if (isValid) {
                    this.message = "";
                    this.startLoading();
                    PersonService.moveToAnotherAccount(this.moveRequest).then(
                        () => {
                            Workspaces.refreshWorkspace().then(() => this.loadUserIfPossible());
                            this.successful = true;
                            this.editEmailMode = false
                            this.stopLoading();
                        },
                        error => this.errorHandling(error)
                    );
                } else {
                    return Promise.reject();
                }
            }
        )
    }

    private changeAccountForCompany() {
        this.$validator.validate('email').then(
            isValid => {
                if (isValid) {
                    this.message = "";
                    this.startLoading();
                    CompanyService.moveToAnotherAccount(this.moveRequest).then(
                        () => {
                            Workspaces.refreshWorkspace().then(() => this.loadUserIfPossible());
                            this.successful = true;
                            this.editEmailMode = false
                            this.stopLoading();
                        },
                        error => this.errorHandling(error)
                    );
                } else {
                    return Promise.reject();
                }
            }
        )
    }

    private errorHandling(err: any) {
        this.successful = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), {email: err?.response?.data.param}) as string;
        this.stopLoading();
    }

    handleCompaniesName(companies: Array<CompanyDTO>): string {
        let res = "";
        for (let i = 0; i < companies.length; i++) {
            res += companies[i].name.concat(i == companies.length - 1 ? "" : ", ");
        }
        return res;
    }
}
